<template>
    <div class="ubicacion">
        <v-card>
            <!-- alerta CRUD -->
            <v-snackbar v-model="alerta" :color="colorAlerta" right top :timeout="timeout">
                {{ textoAlerta }}
                <template v-slot:action="{ attrs }">
                    <v-btn text v-bind="attrs" @click="alerta = false">
                        Cerrar
                    </v-btn>
                </template>
            </v-snackbar>
            <!-- fin alerta CRUD -->
            <!-- modal agregar/editar -->
            <v-dialog v-model="modalPlantilla" max-width="500px" persistent>
                <v-card>
                    <v-toolbar color="primary" dark>
                        <v-card-title>
                            <span class="text-h5">{{ tituloPlantilla }}</span>
                        </v-card-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-container>
                            <v-form ref="frmPlantilla" v-model="validoPlantilla" lazy-validation>
                                <v-text-field v-model="editPlantilla.nombre" :rules="reglaNombrePlantilla"
                                    prepend-inner-icon="mdi-format-text" label="Name" outlined clearable required>
                                </v-text-field>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="error" class="mr-4" @click="cancelarPlantilla">
                                        Cancelar
                                    </v-btn>
                                    <v-btn :disabled="!validoPlantilla" color="success" class="mr-4"
                                        @click="guardarPlantilla">
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                            </v-form>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <!-- modal agregar/editar fin -->
            <!-- modal confirmacion eliminar -->
            <v-dialog v-model="modalEliminarPlantilla" max-width="550px">
                <v-card>
                    <v-card-title class="text-h6 font-weight-ligth text--secondary">
                        <v-icon x-large left color="error">
                            mdi-alert-octagon-outline
                        </v-icon>
                        ¿Estas seguro que deceas eliminar este campo?
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error" @click="cerrarEliminarPlantilla">
                            Cancelar
                        </v-btn>
                        <v-btn color="success" @click="confirmarElimPlantilla">
                            Aceptar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- modal confirmacion eliminar fin -->
            <!-- tabla -->
            <tabla :header="headersPlantilla" :body="plantillas" tituloTabla="PLANTILLA" :accionAgregar="true"
                :exportar="true" @recargar="listarPlantilla" @agregar="agregarPlantilla" @actualizar="editarPlantilla"
                @eliminar="eliminarPlantilla" />
            <!-- tabla fin -->
        </v-card>
    </div>
</template>
<style>

</style>
<script>
    import {
        mapState,
        mapActions
    } from "vuex";
    import tabla from "../components/view/Tabla";
    export default {
        name: "ubicacion",
        components: {
            tabla,
        },
        data: () => ({
            url_api: process.env.VUE_APP_URL_API,
            tab: null,

            alerta: false,
            textoAlerta: '',
            colorAlerta: '',
            timeout: 2000,
            // variables plantilla 
            UidPlantilla: null,
            buscarPlantilla: '',
            validoPlantilla: true,
            modalPlantilla: false,
            modalEliminarPlantilla: false,
            headersPlantilla: [{
                    text: '#',
                    value: 'numero'
                },
                {
                    text: 'Nombre / País',
                    align: 'start',
                    value: 'nombre',
                },
                {
                    text: 'Acciones',
                    value: 'acciones',
                    sortable: false
                },
            ],
            plantillas: [],
            reglaNombrePlantilla: [
                v => !!v || 'El nombre de la plantilla es obligatorio.'
            ],
            editedPlantillaIndex: -1,
            editPlantilla: {
                nombre: ''
            },
            defaultPlantilla: {
                nombre: ''
            },
        }),

        computed: {
            ...mapState(["token"]),
            // titulo modal plantilla 
            tituloPlantilla() {
                return this.editedPlantillaIndex === -1 ? 'Nuevo Plantilla' : 'Editar Plantilla'
            },
        },

        watch: {
            // modal plantilla
            modalPlantilla(val) {
                val || this.cancelarPlantilla()
            },
            modalEliminarPlantilla(val) {
                val || this.cerrarEliminarPlantilla()
            },
        },

        created() {
            // listar plantilla
            this.listarPlantilla()
        },

        methods: {
            ...mapActions(['cerrarSesion']),

            // CRUD plantillas 
            async listarPlantilla() {
                try {
                    await fetch(`${this.url_api}/plantilla`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.plantillas = response.body
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },
            agregarPlantilla(estado) {
                this.modalPlantilla = estado
            },
            editarPlantilla(plantilla) {
                this.editedPlantillaIndex = this.plantillas.indexOf(plantilla)
                this.editPlantilla = Object.assign({}, plantilla)
                this.modalPlantilla = true
                this.UidPlantilla = plantilla.Uid;

            },

            eliminarPlantilla(plantilla) {
                this.editedPlantillaIndex = this.plantillas.indexOf(plantilla)
                this.editPlantilla = Object.assign({}, plantilla)
                this.modalEliminarPlantilla = true
                this.UidPlantilla = plantilla.Uid;
            },

            async confirmarElimPlantilla() {
                try {
                    await fetch(`${this.url_api}/plantilla/${this.UidPlantilla}`, {
                            method: 'DELETE',
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body == 'exito') {
                                this.alerta = false;
                                this.textoAlerta = 'Dato eliminado con Exito!';
                                this.colorAlerta = 'warning'
                                this.alerta = true;
                                this.plantillas.splice(this.editedPlantillaIndex, 1);
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            } else if (response.error) {
                                this.alerta = false;
                                this.textoAlerta = 'Error al eliminar!';
                                this.colorAlerta = 'error'
                                this.alerta = true;
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
                this.cerrarEliminarPlantilla();
            },

            cancelarPlantilla() {
                this.$refs.frmPlantilla.reset()
                this.$refs.frmPlantilla.resetValidation()
                this.modalPlantilla = false
                this.$nextTick(() => {
                    this.editPlantilla = Object.assign({}, this.defaultPlantilla)
                    this.editedPlantillaIndex = -1
                })
            },

            cerrarEliminarPlantilla() {
                this.modalEliminarPlantilla = false
                this.$nextTick(() => {
                    this.editPlantilla = Object.assign({}, this.defaultPlantilla)
                    this.editedPlantillaIndex = -1
                })
            },

            async guardarPlantilla() {
                if (this.$refs.frmPlantilla.validate()) {
                    if (this.editedPlantillaIndex > -1) {
                        try {
                            await fetch(`${this.url_api}/plantilla/${this.UidPlantilla}`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify({
                                        "nombrePlantilla": this.editPlantilla.nombre
                                    })
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato actualizado con Exito!';
                                        this.colorAlerta = 'primary'
                                        this.alerta = true;
                                        Object.assign(this.plantillas[this.editedPlantillaIndex], this
                                            .editPlantilla);
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al actualizar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                        this.UidPlantilla = null
                    } else {
                        try {
                            await fetch(`${this.url_api}/plantilla`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify({
                                        "nombrePlantilla": this.editPlantilla.nombre
                                    })
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato agregado con Exito!';
                                        this.colorAlerta = 'success'
                                        this.alerta = true;
                                        this.listarPlantilla();
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al agregar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                    }
                    this.cancelarPlantilla();
                }
            },
        },
    }
</script>